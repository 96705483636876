const envConfigs = {
  dev: {
    uploadsUrl: "http://192.168.1.248:8056/assets/",
    hostUrl: "http://192.168.1.248:8056",
  },
  prod: {
    uploadsUrl: "https://app.rodriguesvendas.com.br/assets/",
    hostUrl: "https://app.rodriguesvendas.com.br",
  },
};

const configs =
  process.env.REACT_APP_ENV === "production" ? envConfigs.prod : envConfigs.dev;

export default configs;
