import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Alert, CssBaseline, Snackbar } from "@mui/material";
import "./App.css";

// Import pages
import Home from "./pages/Home";
import Cart from "./pages/Cart";
import Supplier from "./pages/Supplier";
import Product from "./pages/Product";
import Login from "./pages/Login";
import Layout from "./pages/Layout";
import Category from "./pages/Category";
import configs from "./config";
import { ItemsService } from "./services/ItemsService";
import { CartService } from "./services/CartService";
import MyOrders from "./pages/MyOrders";
import UsersService from "./services/UsersService";
import { DirectusClient } from "./lib/DirectusClient";
import { withToken, readItems, refresh } from "@directus/sdk";
import ResponsiveAppBar from "./components/ResponsiveNavBar";
import { formatToBrazilianCurrency } from "./lib/util";

type SupplierType = {
  id: string;
  name: string;
};

type ProductType = {
  id: string;
  name: string;
  image: string; // Assuming products have an image
  price: number;
  category: string;
  category_name?: string;
  sku?: string;
  product_type?: string;
  thumb?: string;
  product_variations?: any[];
  stock?: number;
  weight?: number;
  dimensions?: string;
  description?: string;
  short_description?: string;
  supplier?: string | number;
  supplier_name?: string;
  price_range?: string;
};
type OrderItems = {
  product_id: number;
  product: string;
  product_parent?: number;
  price: number;
  ipi?: number;
  quantity: number;
};
export const drcClient = new DirectusClient();

const App: React.FC = () => {
  const storageFilter = localStorage.getItem("currentFilter");
  const [currentList, setCurrentList] = useState<"orders" | "products" | "">(
    ""
  );
  const [paymentConditions, setPaymentConditions] = React.useState<any[]>([]);
  const [product, setProduct] = useState<any>({});
  const [selectedVariant, setSelectedVariant] = useState<any>({});
  const [selectedPaymentCondition, setSelectedPaymentCondition] =
    React.useState("");
  const [productVariations, setProductVariations] = useState<any[]>([]);
  const [products, setProducts] = useState<ProductType[] | any[]>([]);
  const [orders, setOrders] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const perPage = 10;
  const [filteredCategories, setFilteredCategories] = useState<
    string[] | any[]
  >([]);
  const [currentFilter, setCurrentFilter] = useState<any>(
    storageFilter ? JSON.parse(storageFilter) : {}
  );
  const [suppliers, setSuppliers] = useState<SupplierType[] | any[]>([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState<
    SupplierType[] | any[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = React.useState<string | null>(null);

  useEffect(() => {
    fetch(configs.hostUrl + "/server/info")
      .then((response) => response.json())
      .then((data) => {
        const responseObj = data.data;
        const projectInfo = {
          project_name: responseObj.project.project_name,
          project_logo: responseObj.project.project_logo,
          project_color: responseObj.project.project_color,
        };
        localStorage.setItem("projectInfo", JSON.stringify(projectInfo));
      })
      .catch((error) => console.error(error));

    const fetchSuppliers = async () => {
      const itemsService = new ItemsService();
      try {
        const response = await itemsService.getItems(
          { collection: "suppliers", meta: null, schema: null },
          { fields: ["id", "name"] }
        );
        setSuppliers(response);
        setFilteredSuppliers(response);
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCategories = async () => {
      const itemsService = new ItemsService();
      try {
        const response = await itemsService.getItems(
          { collection: "categories", meta: null, schema: null },
          { fields: ["id", "name", "parent"] }
        );
        setFilteredCategories(response);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    checkTokenExpired();
    fetchMyOrders();
    fetchCategories();
    fetchSuppliers();
    fetchPaymentConditions();
  }, []);

  useEffect(() => {
    if (currentList === "products") fetchProducts();
    if (currentList === "orders") fetchMyOrders();
  }, [currentFilter, currentPage]);

  const handleFilter = (filter: any) => {
    const newFilter = { ...currentFilter };

    if (filter.supplier) {
      if (newFilter.supplier === filter.supplier) {
        delete newFilter.supplier;
      } else {
        newFilter.supplier = filter.supplier;
      }
    }

    if (filter.category) {
      if (newFilter.category === filter.category) {
        delete newFilter.category;
      } else {
        newFilter.category = filter.category;
      }
    }

    // product
    if (filter.name || filter.name === "") {
      if (filter.name === "") {
        delete newFilter.name;
      } else {
        newFilter.name = {
          _contains: filter.name,
        };
      }
    }

    setCurrentFilter(newFilter);
    setCurrentPage(1);
    localStorage.setItem("currentFilter", JSON.stringify(newFilter));
  };

  const fetchProductVariation = async (id: string | number) => {
    const itemsService = new ItemsService();
    try {
      const response = await itemsService.getItem(
        { collection: "product_variations", meta: null, schema: null },
        id as string
      );

      return response;
    } catch (error) {
      console.error("Error fetching product variations:", error);
    }
  };

  const getProductVariations = async (id: string | number) => {
    const itemsService = new ItemsService();
    try {
      const response = await itemsService.getItem(
        {
          collection: "products_product_variations_1",
          meta: null,
          schema: null,
        },
        id as string
      );
      const productVariationId = response.product_variations_id;
      const productVariation = await fetchProductVariation(productVariationId);

      return productVariation;
    } catch (error) {
      console.error("Error fetching product variations:", error);
    }
  };

  const fetchProduct = async (id: number | string) => {
    const itemsService = new ItemsService();
    try {
      // Fetch products for the supplier
      const response = await itemsService.getItem(
        {
          collection: "products",
          meta: null,
          schema: null,
        },
        id as string
      );
      let maxPrice = response.price;
      let minPrice = response.price;

      const productVariations = response.product_variations;
      const variableProducts: any = [];
      for (let i = 0; i < productVariations.length; i++) {
        const variationId = productVariations[i];
        const variation = await getProductVariations(variationId);
        variableProducts.push(variation);
        if (variation) {
          if (variation.price > maxPrice) maxPrice = variation.price;
          if (variation.price < minPrice) minPrice = variation.price;
        }
      }
      response.price_range = `${formatToBrazilianCurrency(
        minPrice
      )} - ${formatToBrazilianCurrency(maxPrice)}`;

      const grabSuppliers = await itemsService.getItems(
        { collection: "suppliers", meta: null, schema: null },
        { fields: ["id", "name"] }
      );
      const supplierName = grabSuppliers.find(
        (supplier: any) => supplier.id === response.supplier
      );
      if (supplierName) {
        response.supplier_name = supplierName.name;
      }
      const grabCategories = await itemsService.getItems(
        { collection: "categories", meta: null, schema: null },
        { fields: ["id", "name"] }
      );
      const categoryName = grabCategories.find(
        (category: any) => category.id === response.category
      );
      if (categoryName) {
        response.category_name = categoryName.name;
      }
      // response.product_variations = variableProducts;
      setProductVariations(variableProducts);
      setProduct(response);
    } catch (error) {
      setError("Error fetching products");
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProducts = async () => {
    setCurrentList("products");
    const itemsService = new ItemsService();
    const countAllProducts = await itemsService.countAllItems(
      {
        collection: "products",
        meta: null,
        schema: null,
      },
      {
        ...(currentFilter && { filter: currentFilter }),
      }
    );
    try {
      // Fetch products for the supplier
      const response = await itemsService.getItems(
        { collection: "products", meta: null, schema: null },
        {
          fields: [
            "id",
            "name",
            "thumb",
            "stock",
            "supplier",
            "price",
            "product_variations",
            "product_type",
          ],
          limit: perPage,
          page: currentPage,
          ...(currentFilter && { filter: currentFilter }),
        }
      );

      // grab all product variations for each product in this list
      const variableProducts: any = [];
      for (let i = 0; i < response.length; i++) {
        const product = response[i];
        let maxPrice = product.price;
        let minPrice = product.price;

        if (product.product_type !== "variable") continue;

        for (let j = 0; j < product.product_variations.length; j++) {
          const variationId = product.product_variations[j];
          const variation = await getProductVariations(variationId);
          variableProducts.push(variation);
          if (variation) {
            if (variation.price > maxPrice) maxPrice = variation.price;
            if (variation.price < minPrice) minPrice = variation.price;
          }
        }
        product.price_range = `${formatToBrazilianCurrency(
          minPrice
        )} - ${formatToBrazilianCurrency(maxPrice)}`;
        const grabSuppliers = await itemsService.getItems(
          { collection: "suppliers", meta: null, schema: null },
          { fields: ["id", "name"] }
        );
        const supplierName = grabSuppliers.find(
          (supplier: any) => supplier.id === product.supplier
        );
        if (supplierName) {
          product.supplier_name = supplierName.name;
        }

        const grabCategories = await itemsService.getItems(
          { collection: "categories", meta: null, schema: null },
          { fields: ["id", "name"] }
        );
        const categoryName = grabCategories.find(
          (category: any) => category.id === product.category
        );
        if (categoryName) {
          product.category_name = categoryName.name;
        }
      }
      // const uniqueVariations = variableProducts.filter(
      //   (v: any, i: any, a: any) =>
      //     a.findIndex((t: any) => t.id === v.id) === i
      // );
      // setProductVariations(uniqueVariations);
      setProducts(response);
      // console.log({
      //   response,
      //   variableProducts,
      //   uniqueVariations,
      //   countAllProducts,
      // });
      setTotalPages(Math.ceil(countAllProducts / perPage));
    } catch (error) {
      setError("Error fetching products");
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMyOrders = async () => {
    setCurrentList("orders");
    const itemsService = new ItemsService();
    const usersService: any = new UsersService();
    try {
      let me = await usersService.getUsers();
      me = me.length > 0 ? me[0] : null;
      const countAllOrders = await itemsService.countAllItems(
        {
          collection: "orders",
          meta: null,
          schema: null,
        },
        {
          filter: { client: me.id },
        }
      );
      const myOrders = await itemsService.getItems(
        { collection: "orders", meta: null, schema: null },
        {
          fields: [
            "id",
            "client",
            "payment_condition",
            "total",
            "items",
            "date_created",
          ],
          sort: "-date_created",
          limit: perPage,
          page: currentPage,
          filter: { client: me.id },
        }
      );
      setOrders(myOrders);
      setTotalPages(Math.ceil(countAllOrders / perPage));
    } catch (error) {
      console.error("Error fetching orders:", error);
      // localStorage.removeItem("accessToken");
      // localStorage.removeItem("refreshToken");
    }
  };

  const checkTokenExpired = async () => {
    const accessToken = localStorage.getItem("accessToken") || "";
    const client = drcClient.directusClient;
    try {
      await client.request(
        withToken(
          accessToken,
          readItems("orders", {
            fields: ["id"],
            limit: 1,
          })
        )
      );
    } catch (error: any) {
      // Force logout
      // console.log(`CATCH checkTokenExpired`, {
      //   e: error.errors[0].extensions.code,
      //   a: accessToken,
      // });
      if (error.errors[0].extensions.code === "TOKEN_EXPIRED") {
        // try refresh token
        const client = drcClient.directusClient;
        const refresh_token = localStorage.getItem("refreshToken") || "";
        // const access_token = localStorage.getItem("accessToken") || "";
        const newAccessToken = await client.request(
          refresh("json", refresh_token)
        );
        if (newAccessToken?.access_token && newAccessToken?.refresh_token) {
          localStorage.setItem("accessToken", newAccessToken.access_token);
          localStorage.setItem("refreshToken", newAccessToken.refresh_token);
          window.location.href = "/";
        } else {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("shopping_cart");
          // console.log("Logged out");
          window.location.href = "/login";
        }
        // localStorage.removeItem("accessToken");
        // localStorage.removeItem("refreshToken");
        // localStorage.removeItem("shopping_cart");
        // // console.log("Logged out");
        // window.location.href = "/login";
      }
      if (error.errors[0].extensions.code === "FORBIDDEN") {
        // console.log("ATTENTION", error.errors[0].message);
      }
    }
  };

  const finishOrder = async () => {
    // make sure payment condition is set
    if (Number(selectedPaymentCondition) === 0) {
      setError("Selecione uma condição de pagamento");
      return;
    }
    const cart = CartService.getCart();
    const calculateTotal = () => {
      const total = cart.reduce((acc, item) => {
        const itemTotal = item.price * item.quantity;
        const itemIpi = ((item.ipi || 0) / 100) * itemTotal; // Calculate ipi as a percentage of itemTotal
        return acc + itemTotal + itemIpi;
      }, 0);
      return formatToBrazilianCurrency(total);
    };
    const cartItems = CartService.getCart();
    const itemsService = new ItemsService();
    const usersService: any = new UsersService();

    let me = await usersService.getUsers();
    me = me.length > 0 ? me[0] : null;

    const orderItems: OrderItems[] = cartItems.map((item) => {
      return {
        product_id: Number(item.id),
        ...(item.product_parent && {
          product_parent: Number(item.product_parent),
        }),
        product: item.name,
        price: item.price,
        quantity: item.quantity,
        ...(item.ipi && { ipi: item.ipi }),
      };
    });

    const order = {
      client: me.id,
      supplier: Number(CartService.getOrderSupplier()),
      payment_condition: Number(CartService.getPaymentCondition()),
      items: orderItems,
      total: calculateTotal(),
    };

    // TODO: send order to backend
    try {
      await itemsService.createItem(
        {
          collection: "orders",
          schema: null,
          meta: null,
        },
        order
      );
      setSuccess("Pedido finalizado com sucesso");
      // clear cart navigate to my orders
      CartService.clearCart();
      window.location.href = "/my-orders";
    } catch (error) {
      console.error("Failed to create order:", error);
      setError(
        "Falha ao criar pedido. Verifique os estoques ou tente novamente."
      );
    }
  };

  const fetchPaymentConditions = async () => {
    const itemsService = new ItemsService();
    const result = await itemsService.getItems(
      {
        collection: "payment_conditions",
        meta: null,
        schema: null,
      },
      { fields: ["id", "name"] }
    );
    setPaymentConditions(result);
  };

  return (
    <Router>
      <CssBaseline />
      <ResponsiveAppBar
        fetchMyOrders={fetchMyOrders}
        fetchProducts={fetchProducts}
        drcClient={drcClient}
      />{" "}
      {/* Always display the navbar on every page */}
      <Layout
        appLogic={{
          suppliers,
          filteredSuppliers,
          filteredCategories,
          setFilteredSuppliers,
          currentFilter,
          handleFilter,
          loading,
        }}>
        <Routes>
          <Route
            path="/"
            element={
              <Home
                products={products}
                loading={loading}
                error={error}
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
                fetchProducts={fetchProducts}
              />
            }
          />
          <Route path="/category/:id" element={<Category />} />
          <Route path="/supplier/:id" element={<Supplier />} />
          <Route
            path="/product/:id"
            element={
              <Product
                fetchProduct={fetchProduct}
                product={product}
                productVariations={productVariations}
                setProduct={setProduct}
                setError={setError}
                setLoading={setLoading}
                loading={loading}
                error={error}
                setSelectedVariant={setSelectedVariant}
                selectedVariant={selectedVariant}
              />
            }
          />
          <Route path="/login" element={<Login drcClient={drcClient} />} />
          <Route
            path="/cart"
            element={
              <Cart
                setPaymentConditions={setPaymentConditions}
                paymentConditions={paymentConditions}
                fetchPaymentConditions={fetchPaymentConditions}
                finishOrder={finishOrder}
                setSelectedPaymentCondition={setSelectedPaymentCondition}
                selectedPaymentCondition={selectedPaymentCondition}
              />
            }
          />
          <Route
            path="/my-orders"
            element={
              <MyOrders
                paymentConditions={paymentConditions}
                loading={loading}
                error={error}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                totalPages={totalPages}
                orders={orders}
              />
            }
          />
          {/* <Route path="/checkout" element={<Checkout />} /> */}
        </Routes>
        {error && (
          <Snackbar
            open={Boolean(error)}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            onClose={() => setError(null)}>
            <Alert onClose={() => setError(null)} severity="error">
              {error}
            </Alert>
          </Snackbar>
        )}
        {success && (
          <Snackbar
            open={Boolean(success)}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={6000}
            onClose={() => setSuccess(null)}>
            <Alert onClose={() => setSuccess(null)} severity="success">
              {success}
            </Alert>
          </Snackbar>
        )}
      </Layout>
    </Router>
  );
};

export default App;
