// pages/Cart.tsx
import React, { useEffect } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  IconButton,
  MenuItem,
} from "@mui/material";
import { useCart } from "../hooks/useCart";
import DeleteIcon from "@mui/icons-material/Delete";
import { CartService } from "../services/CartService";
import { ItemsService } from "../services/ItemsService";
import { useNavigate } from "react-router-dom";
import { formatToBrazilianCurrency } from "../lib/util";

type CartProps = {
  finishOrder: () => void;
  selectedPaymentCondition: number | string;
  setSelectedPaymentCondition: (e: string) => void;
  fetchPaymentConditions: () => void;
  setPaymentConditions: (conditions: any) => void;
  paymentConditions: any;
};

const Cart: React.FC<CartProps> = ({
  finishOrder,
  selectedPaymentCondition,
  setSelectedPaymentCondition,
  fetchPaymentConditions,
  paymentConditions,
  setPaymentConditions,
}) => {
  const navigate = useNavigate();
  const itemsService = new ItemsService();
  const { cart, removeItem, clearCart, updateItemQuantity } = useCart();
  const calculateTotal = () => {
    const total = cart.reduce((acc, item) => {
      const itemTotal = item.price * item.quantity;
      const itemIpi = ((item.ipi || 0) / 100) * itemTotal; // Calculate ipi as a percentage of itemTotal
      return acc + itemTotal + itemIpi;
    }, 0);
    return formatToBrazilianCurrency(total);
  };
  const [supplierName, setSupplierName] = React.useState("");

  const getSupplierName = async () => {
    const grabSuppliers = await itemsService.getItems(
      { collection: "suppliers", meta: null, schema: null },
      { fields: ["id", "name"] }
    );
    const supplierName = grabSuppliers.find(
      (supplier: any) => supplier.id === Number(CartService.getOrderSupplier())
    );
    setSupplierName(supplierName?.name || "");
  };

  useEffect(() => {
    fetchPaymentConditions();
    getSupplierName();
  }, []);

  return (
    <Box padding="16px" flex="1">
      <Typography variant="h4" gutterBottom>
        Meu Carrinho
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Pedido para fornecedor: {supplierName}
      </Typography>

      {cart.length === 0 ? (
        <Typography>Seu pedido está vazio.</Typography>
      ) : (
        <List>
          {cart.map((item) => (
            <ListItem key={item.id} title="Ver Produto">
              <ListItemText
                onClick={() => {
                  navigate(`/product/${item.id}`);
                }}
                sx={{
                  p: 1,
                  cursor: "pointer",
                  borderRadius: "4px",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
                primary={item.name}
                secondary={`Preço: ${formatToBrazilianCurrency(
                  item.price
                )} | Total: ${formatToBrazilianCurrency(
                  item.price * item.quantity +
                    ((item.ipi || 0) / 100) * (item.price * item.quantity)
                )} |
                ${item.ipi ? `IPI: ${item.ipi}%` : ""}
                ${
                  typeof item.stock === "number"
                    ? ` | Estoque: ${item.stock}`
                    : ""
                }
                ${item.sku ? ` | SKU: ${item.sku}` : ""}`}
              />
              <TextField
                type="number"
                label="Quantidade"
                value={item.quantity}
                onChange={(e) =>
                  updateItemQuantity(item.id, parseInt(e.target.value))
                }
                sx={{ width: "80px", marginRight: "16px" }}
              />
              <IconButton onClick={() => removeItem(item.id)}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      )}
      <Box mt="16px">
        <Typography variant="h6">Total: R$ {calculateTotal()}</Typography>
      </Box>
      {cart.length > 0 && (
        <Box mt="16px">
          <TextField
            select
            label="Condição de Pagamento"
            value={selectedPaymentCondition}
            onChange={(e) => {
              CartService.savePaymentCondition(e.target.value);
              setSelectedPaymentCondition(e.target.value);
            }}
            sx={{ marginRight: "16px", minWidth: "300px" }}>
            {paymentConditions.map((condition: any) => (
              <MenuItem key={condition.id} value={condition.id}>
                {condition.name}
              </MenuItem>
            ))}
          </TextField>
          <br />
          <br />
          <Button variant="contained" color="secondary" onClick={clearCart}>
            Limpar Pedido
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={finishOrder}
            sx={{ marginLeft: "16px" }}>
            Finalizar Pedido
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Cart;
